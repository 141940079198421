.otp-form {
  > div {
    justify-content: center;
    direction: ltr;

    input {
      @apply py-2 px-3 border-2 bg-transparent flex w-full rounded-md shadow-sm border-white/10;
      width: 3rem !important;
    }

    span {
      @apply opacity-25
    }
  }

  div {
    span {
      @apply px-2
    }
  }
}