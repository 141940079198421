@tailwind base;
@tailwind components;
@tailwind utilities;

.price {
  -moz-font-feature-settings: "ss02";
  -webkit-font-feature-settings: "ss02";
  font-feature-settings: "ss02";
}

tr[role="row"] {
  th {
    &:first-child {
      @apply rounded-r-lg
    }
    &:last-child {
      @apply rounded-l-lg
    }
  }
  &:first-child {
    td {
      &:first-child {
        @apply border-gray-200;

        @apply rounded-tr-lg
      }
      &:last-child {
        @apply border-gray-200;

        @apply rounded-tl-lg
      }
    }
  }
  &:last-child {
    td {
      &:first-child {
        @apply border-gray-200;

        @apply rounded-br-lg
      }
      &:last-child {
        @apply border-gray-200;

        @apply rounded-bl-lg
      }
    }
  }

}

.banner-text-container {
  background: rgba(185, 185, 185, 0.16);
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.141124);
  backdrop-filter: blur(28px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 15px;
}

.verify-bg {
  background-image: url("./images/verify.png");
  background-size: cover;
}

table tbody:before {
  content:"@";
  display:block;
  line-height:20px;
  text-indent:-99999px;
  background: #181717;
}

.i-text {
  display: none;
}

.skeleton {
  position: relative !important;
}

.skeleton:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.02) 20%, rgba(255, 255, 255, 0.06) 60%, rgba(255, 255, 255, 0));
  -webkit-animation: shimmer 2s infinite;
  animation: shimmer 2s infinite;
  content: "";
}

@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}